// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import land1 from "./images/Landing/land1.jpg";
import location from "./images/Landing/location.png";
import info from "./images/Landing/info.png";



const resources = {
  eng: {
    translation: {
      
      loading: "Loading...",
      navbar: {
        home: "Home",
        packages: "Packages",
        services: "Services",
        offers: "Offers",
        location: "Location",
        about: "About",
        contact: "Contact",
        testimonials: "Opinions",
        more:"more"
      },
      home: {
        "landingPage": {
          "slides": [
            {
              
              "header": "Closest to You in Khorfakkan",
              "content": "Al Shifa Medical Center",
              "labelBtn": "Find Out More"

              
            },
            {
              "header": "Medical Information",
              "content": "Accurate medical information is the foundation of prevention. Ensure you obtain correct information to make better health decisions.",
              "labelBtn": "Info"

            },
            {
              "header": "Special Packages",
              "content": "Discover the packages we offer to meet your needs.",
              "labelBtn": "Show Package"

            }
          ],
          "salesPackage": {
            "header": "Our Special Offers",
            "discountTag": "{salePercent}% OFF",
            noOffer:"NO OFFER",


            "packageButton": "Discover the Offer"
          },
          "partnerLogos": {
            "title": "Insurance Partners"
          }
        },

        info: {
        "features": [
          {
            "title": "We strive for leadership in excellence.",
            "description": "We have come a long way on our journey to excellence; our aspirations have risen, and we now strive for leadership in excellence.",
            "icon": "running"
          },
          {
            "title": "Most Competitive and Productive",
            "description": "The most competitive and productive for all stakeholders by continuing our innovative and sustainable working style.",
            "icon": "recycle"
          },
          {
            "title": "High-Quality Medical Services and Analyses",
            "description": "Providing high-quality and accurate medical services and analyses at competitive prices for all stakeholders.",
            "icon": "microscope"
          }
        ]
        },
        other:{
        "contact": {
          "button": "Contact Us",
          "divider": "OR",
          "socialMediaHeader": "Join our community on social media",
          "contactUs": "Contact Us:"
        },
        "request": {
            "header": "Do you have any questions or suggestions?",
            "description": "Send us a message, and we will get back to you with a team of experts",
            "button": "Leave a message"
          }

      },
       
      }
      ,
      services: {
      "title": "Our Services",
      "description": "Explore our diverse services",
      "service": [
        {
          "id": 1,
          "name_en": "Medical Information",
          "description_en": "Get accurate and up-to-date medical information on various medical conditions and available treatments.",
          "icon": "info",
          "path": "/information"
        },
        {
          "id": 2,
          "name_en": "Laboratory Reports Request",
          "description_en": "Submit a request for accurate and comprehensive laboratory reports for all medical tests.",
          "icon": "report",
          "path": "/reports"
        },
        {
          "id": 3,
          "name_en": "Home Withdrawal",
          "description_en": "The home withdrawal service allows you to conduct tests at home with comfort and ease.",
          "icon": "home",
          "path": "/withdraw"
        },
        {
          "id": 4,
          "name_en": "Packages",
          "description_en": "Discover various health packages that cover all testing and treatment needs at competitive prices.",
          "icon": "package",
          "path": "/package"
        }
      ]
      },
      packages: { // Package translations
        title: "Available Packages",
        description: "Browse our selection of packages tailored for your needs.",
        noPackages: "No packages available in this category.",
        more: "More",
        offer:"Special Offers",
        bookNow: "Book Now",
        test:"tests",
        "onSale": "Sale",
        "salePrice": "Offer Price",
        "saleDuration": "Offer Duration",
        "saleTimeLeft": "Time Left",
        home:{
          more:"All Packages"
        }
      },
      offer: {
        "salesPackage": {
         "header": "Our Offers",
            "description": "Explore our exclusive offers and discounts!",
            "discountTag": "Discount",
            "offerDuration": "Offer Duration"
          },
        "packages": {
          "noPackages": "No offers available at the moment.",
          "more": "More Details",
          "bookNow": "Book Now"
        },
        "specialOffer":"Special Offer",
        "packageOffer":"Package's Offers",
        start:"start",
        end:"end",
        "test":"Tests",
        "timeleft":"Time Left",
        "offerDuration": "Duration"


        },

      survey: {
        title: 'Participate in Our Survey',
        description: 'We value your feedback. Please take a moment to complete our survey.',
        start: 'Start Survey',
      },
      details: {
        tests: 'Tests',
        details: 'Details',
        currentPrice: 'Current Price',
        originalPrice: 'Original Price',
        discount: 'Discount',
        offerEndsIn: 'Offer Ends In',
        price: 'Price',
        bookNow: 'Book Now',
        testsNumber:"Tests Number",
        testsNumberSale:"Tests' Sale" ,
        "start":"Start",
        "end":"End",
        "offerDuration":"Duration",
        "timeLeft": "Time Left For end The Sale",
        specialOffer:"Special Offer",
        onSale:"Package's Sale",
        testSale:"Package'S Sale Tests"
      },
      medicalInformation: {
        title: "Medical Information",
        description: "Get updated medical information to improve your health and wellness.",
        generalTab: "General Medical Information",
        weeklyTab: "Weekly Information",
        noData: "No data available",
        availableInfo: "weekly Information",
        noWeeklyInfo: "No information available for this week.",
      },
      "months": {
        "january": "January",
        "february": "February",
        "march": "March",
        "april": "April",
        "may": "May",
        "june": "June",
        "july": "July",
        "august": "August",
        "september": "September",
        "october": "October",
        "november": "November",
        "december": "December"
      },
      about: {
        title: "About Us",
        description: "We are committed to your health and well-being.",
        story: {
          title: "Our Story",
          text: "Initially, we recognized the need for an advanced medical laboratory providing high-quality services to our community in Khor Fakkan. Our journey began in 2020 when a team of experienced doctors and technicians came together to establish this laboratory. Since then, we have tirelessly worked to offer innovative and safe laboratory services that meet the needs of anyone seeking healthcare. We are proud to contribute to improving public health in our region by providing accurate and timely results."
        },
        vision: {
          title: "Our Vision",
          text: "To be the leading provider of medical laboratory services on the eastern coast by offering outstanding and advanced services that contribute to improving the quality of healthcare. We aim to be the first choice for the community by focusing on innovation and quality in all aspects of our services while adhering to the highest standards of integrity and respect."
        },
        mission: {
          title: "Our Mission",
          text: "Quality, accuracy, and speed at competitive prices for all involved, while ensuring the best possible experience for patients. We strive to improve our services by listening to customer feedback and implementing the latest technologies in the field of medical laboratories."
        },
        values: {
          title: "Our Values",
          text: "1. Excellence: We always strive to provide the best in everything we do. 2. Quality: We are committed to delivering high-quality services that meet our standards. 3. Integrity: We operate transparently and ensure integrity in all our dealings. 4. Respect: We value every individual and treat everyone with respect and dignity."
        },
      },
      location: {
        title: "Our Location",
        description: "We invite you to visit us and explore our services",
        addressTitle: "Address",
        country: "United Arab Emirates",
        governorate: "Emirate Of Sharjah",
        city: "Khorfakkan",
        scheduleTitle: "Ramadan Working Hours",
        BeforeIftar: "Before Iftar",
        AfterIftar: "After Iftar",
        scheduleTitleRamdan: "Ramadan Working Hours",
        schedule: {
            daily: "From Saturday To Tuesday",
            dailyTime: "From 9 AM to 10 PM",
            dailyBeforeIftar: "From 9.30 AM to 5.30 PM ",
            dailyAfterIftar: "From 7.30 PM to 11.30 PM ",
            

            friday: "Friday",
            fridayTime: "From 4:30 PM to 10 PM",
            fridayAfterIftar: "From 7.30 PM to 12 AM",

            mondayTime: "From 9 AM to 1 PM & 4:30 PM to 9:30 PM",

            monday: "Monday",
            mondayBeforeIftar: "From 9 AM to 1 PM ",
            mondayAfterIftar: "From 7.30 AM to 11 PM ",
            
            
            saturday: "Saturday",
            saturdayTime: "From 9 AM to 10 PM",
            
            sunday: "Sunday",
            sundayTime: "From 9 AM to 10 PM",
            
            tuesday: "Tuesday",
            tuesdayTime: "From 9 AM to 10 PM",
            
            thursday: "Thursday",
            thursdayTime: "From 9 AM to 10 PM",
            
            
            
            wednesday: "Wednesday",
            wednesdayTime: "( From 9 AM to 1 PM )  & ( 4:30 PM to 9:30 PM )",
            

            }
        ,
        viewPackages: "View Our Packages"
      },
      contact: {
      "header": "Contact Us",
      "subHeader": "Connect via Social Media",
      "address": {
        "title": "Address",
        "location": "Sharjah, Khorfakkan"
      },
      "social": {
        "facebook": "Facebook",
        "whatsapp": "WhatsApp",
        "instagram": "Instagram",
        "phone": "Phone Number",
        "email": "Email",
        "address": "Address"
      },
      "form": {
        "inquiry": "Inquiry",
        "suggestion": "Suggestion",
        "name": "Name",
        "email": "Email",
        "phone": "Phone",
        "message": "Message",
        "submit": "Submit",
        "loading": "Submitting...",
        "error": "All fields are required."
      }
      },
      reportRequest: {
            title: "Request Laboratory Reports",
            instructions: "Please fill out the form below to request your laboratory reports.",
            fullName: "Full Name",
            email: "Email",
            phone: "Phone Number",
            visitTime: "Visit Time",
            submit: "Submit"
          },
      withdrawal: {
        header: "Home Withdrawal",
        instructions: "Please fill out the form below to schedule a home withdrawal.",
        fullName: "Full Name",
        email: "Email",
        phone: "Phone Number",
        appointmentDate:"Choose Date",
        appointmentTime:"Choose Time",
        notes: "Notes",
        submit: "Submit",
        chooseId: "Choose Identity File",
        chooseInsurance: "Choose Insurance File",
      },
      booking: {
            header: "Booking",
            noPackageData: "No package data available. Please return to the previous page.",
            price: "Price",
            offerEnds: "Offer ends in",
            name: "Name",
            namePlaceholder: "Enter your name",
            email: "Email",
            emailPlaceholder: "Enter your email",
            phone: "Phone Number",
            phonePlaceholder: "Enter your phone number",
            notes: "Notes",
            notesPlaceholder: "Any additional notes",
            successMessage: "Booking request sent successfully!",
            errorMessage: "Error",
            generalErrorMessage: "An error occurred while sending the request. Please try again.",
            tests:"tests",
            submit: "Booking Now",
          },
      footer:{
        "sections": [
          {
            "header": "Al-Shifa Medical Center Laboratory",
            "links": [
              { "label": "About Us", "url": "/aboutPage" },
              { "label": "Contact Us", "url": "/contact" },
              { "label": "Survey", "url": "https://surveyheart.com/form/5e89c1aef411e847dc5706ca" }            ]
          },
          {
            "header": "Our Services",
            "links": [
              { "label": "Offers", "url": "/offer/" },
              { "label": "Laboratory Reports Request", "url": "/reports" },
              { "label": "Home Withdrawal", "url": "/withdraw" }
            ]
          },
          {
            "header": "Support",
            "links": [
              { "label": "Medical Information", "url": "/information" },
              { "label": "Privacy Policy", "url": "/privacypolicy" },
              { "label": "Developer Information", "url": "https://muhammedaly.vercel.app/" }
            ]
          }
        ],
        "socialMedia": {
          "apple": "Apple",
          "facebook": "Facebook",
          "twitter": "Twitter",
          "shop": "Shop",
          "whatsapp": "WhatsApp"
        },
        "rights": {
          "text": "All rights reserved by",
          "companyName": "Al-Shifa Medical Center Laboratory",
          "companyName1": "Al-Shifa",
          "companyName2": "Medical Center",
          "companyName3": " Laboratory",
          "year":"2024 ©"

        }
      },
      opinion: {
          "title": "Our Followers' Opinions",
          "subheading": "Your feedback inspires us to always deliver the best",
          "googleReviewButton": "Write your review on Google"
        }
      ,      
      currency:"AED",
      discount:"Discount",
      day:"day",
      days:"days"
    },
  },

  

  ar: {
    translation: {
      "loading": "جاري التحميل...",
      navbar: {
        home: "الرئيسية",
        packages: "الباقات",
        services: "الخدمات",
        offers: "العروض",
        location: "الموقع",
        about: "من نحن",
        contact: "تواصل معنا",
        testimonials: "الاراء",
        more:"المزيد"

      },
      home: {
      "landingPage": {
        "slides": [
          {
            "header": "الأقرب إليك بخورفكان",
            "content": "مختبر مركز الشفاء الطبي",
            "labelBtn": "اكتشف المزيد"


          },
          {
            "header": "معلومة طبية",
            "content": "المعلومات الطبية الدقيقة هي أساس الوقاية. احرص على الحصول على المعلومات الصحيحة لاتخاذ قرارات صحية أفضل.",
            "labelBtn": "اكتشف المزيد"

          },
          {
            "header": "الباقات المميزة",
            "content": "اكتشف الباقات التي نقدمها لتلبية احتياجاتك.",
            "labelBtn": "اكتشف المزيد"

          }
        ],
        "salesPackage": {
          "header": "عروضنا الخاصة",
          "discountTag": "{salePercent}% OFF",
          "packageButton": "اكتشف العرض",
          noOffer:"لايوجد اي عروض"
        },
        "partnerLogos": {
          "title": "شركاء التأمين"
        }
      },
      info: {
      "features": [
        {
          "title": "نسعى للريادة في التميز.",
          "description": "قطعنا شوطاً كبيراً من رحلتنا للتميز فقد ارتفع سقف طموحنا وأصبحنا نسعى للريادة في التميز.",
          "icon": "running"
        },
        {
          "title": "الأكثر تنافسية وإنتاجية",
          "description": "الأكثر تنافسية وإنتاجية لجميع المعنيين من خلال الاستمرار في أسلوب عملنا المبتكر والمستمر والمستدام.",
          "icon": "recycle"
        },
        {
          "title": "خدمات وتحاليل طبية عالية",
          "description": "تقديم خدمات وتحاليل طبية عالية الجودة ودقيقة وبأسعار تنافسية لجميع الأشخاص المعنيين.",
          "icon": "microscope"
        }
      ]
    },
      other:{
        "contact": {
          "button": "تواصل معنا",
          "divider": "أو",
          "socialMediaHeader": "انضم إلى مجتمعنا على وسائل التواصل الاجتماعي",
          "contactUs": "اتصل بنا:"
        },
        request:{
          "header": "هل لديك أي استفسار أو اقتراح؟",
          "description": "ارسل الينا وسنتواصل معك من مجموعة من الخبراء",
          "button": "اترك الرسالة"
        }

      }
      },
      services: {
      "title": "خدماتنا",
      "description": "استكشف خدماتنا المتنوعة",
      "service": [
        {
          "id": 1,
          "name_en": "معلومات طبية",
          "description_en": "احصل على معلومات طبية دقيقة وحديثة عن الحالات الطبية المختلفة والعلاجات المتاحة.",
          "icon": "info",
          "path": "/information"
        },
        {
          "id": 2,
          "name_en": "طلب تقارير مخبرية",
          "description_en": "تقديم طلب للحصول على تقارير مخبرية دقيقة وشاملة لجميع الفحوصات الطبية.",
          "icon": "report",
          "path": "/reports"
        },
        {
          "id": 3,
          "name_en": "السحب المنزلي",
          "description_en": "خدمة السحب المنزلي تسمح لك بإجراء التحاليل في المنزل بكل راحة وسهولة.",
          "icon": "home",
          "path": "/withdraw"
        },
        {
          "id": 4,
          "name_en": "الباقات",
          "description_en": "اكتشف الباقات الصحية المختلفة التي تغطي جميع احتياجات الفحوصات والعلاج بأسعار مميزة.",
          "icon": "package",
          "path": "/package"
        }
      ]
    },
      packages: { // Package translations in Arabic
        title: "الباقات المتاحة",
        description: "استعرض مجموعة من الباقات المصممة حسب احتياجاتك.",
        noPackages: "لا توجد باقات متاحة في هذه الفئة.",
        more: "المزيد",
        bookNow: "احجز الان",
        offer:"العروض الخاصة",
        test:"عدد الفحوصات",
        "onSale": "عرض ",
        "salePrice": "سعر العرض",
        "saleDuration": "مدة العرض",
        "saleTimeLeft": "الوقت المتبقي",


        home:{
          more:"شاهد جميع الباقات"
        }
      },
      offer:{
      "salesPackage": {
      "header": "عروضنا",
      "description": "استكشف عروضنا الحصرية وخصوماتنا!",
      "discountTag": "خصم",
        },
        "packages": {
        "noPackages": "لا توجد عروض متاحة في الوقت الحالي.",
        "more": "المزيد من التفاصيل",
        "bookNow": "احجز الآن"
      },
      start:"بداية العرض",
      end:"نهاية العرض",
      "specialOffer":"العروض الخاصة",
        "packageOffer":"عروض الباقات",
      "test":"عدد الفحوصات",
      "timeleft":"الوقت المتبقي",
      "offerDuration": "مدة العرض"


      },
      survey: {
        title: 'شارك في استطلاع الرأي الخاص بنا',
        description: 'نود أن نسمع رأيك. الرجاء تخصيص لحظة لإكمال استطلاعنا.',
        start: 'ابدأ الاستطلاع',
      },
      details: {
        tests: 'الفحوصات',
        details: 'تفاصيل',
        currentPrice: 'السعر الحالي',
        originalPrice: 'السعر الأصلي',
        discount: 'خصم',
        offerEndsIn: 'ينتهي العرض خلال',
        price: 'السعر',
        bookNow: 'احجز الآن',
        testsNumber:"عدد الفحوصات",
        testsNumberSale:"عدد فحوصات العرض" ,
        "start":"بداية العرض",
        "end":"نهاية العرض",
        "offerDuration":"مدة العرض",
        "timeLeft": " ينتهي العرض خلال ",
        specialOffer:"عرض خاص",
        onSale:"تخفيض باقة",
        expird:"انتهي"
        
      },
      medicalInformation: {
        title: "معلومات طبية",
        description: "احصل على معلومات طبية محدثة بشكل دوري لتحسين صحتك وعافيتك.",
        generalTab: "معلومات طبية عامة",
        weeklyTab: "معلومات الأسبوع",
        noData: "لا توجد بيانات متاحة",
        availableInfo: "معلومة الاسبوع",
        noWeeklyInfo: "لا توجد معلومات متاحة لهذا الأسبوع.",
      },
      "months": {
      "january": "يناير",
      "february": "فبراير",
      "march": "مارس",
      "april": "أبريل",
      "may": "مايو",
      "june": "يونيو",
      "july": "يوليو",
      "august": "أغسطس",
      "september": "سبتمبر",
      "october": "أكتوبر",
      "november": "نوفمبر",
      "december": "ديسمبر"
    },
      about: {
        title: "من نحن",
        description: "ملتزمون بصحتكم ورفاهيتكم",
        story: {
          title: "قصتنا",
          text: "في البداية أدركنا مدى حاجة مدينة خورفكان لوجود مختبر طبي متطور يقدّم خدمات عالية الجودة لمجتمعنا. بدأت رحلتنا في عام 2020، حيث اجتمع فريق من الأطباء والفنيين ذوي الخبرة لتأسيس هذا المختبر. منذ ذلك الحين، نحن نعمل بلا كلل لنقدم خدمات مختبرية مبتكرة وآمنة تلبي احتياجات كل من يحتاج إلى الرعاية الصحية. نحن فخورون بأننا نساهم في تحسين الصحة العامة في منطقتنا من خلال تقديم نتائج دقيقة وسريعة."
        },
        vision: {
          title: "رؤيتنا",
          text: "أن نكون مُزَوِّد خدمات المختبرات الطبية الرائد في الساحل الشرقي من خلال تقديم خدمات متميزة ومتطورة تسهم في تحسين جودة الرعاية الصحية. نهدف إلى أن نكون الخيار الأول للمجتمع من خلال التركيز على الابتكار والجودة في جميع جوانب خدماتنا، مع الالتزام بأعلى معايير النزاهة والاحترام."
        },
        mission: {
          title: "رسالتنا",
          text: "الجودة ودقة وسرعة بأسعار تنافسية لجميع الأشخاص المعنيين، مع الالتزام بتقديم أفضل تجربة ممكنة للمرضى. نحن نسعى جاهدين لتحسين خدماتنا من خلال الاستماع لملاحظات العملاء وتطبيق أحدث التقنيات في مجال المختبرات الطبية."
        },
        values: {
          title: "قيمنا",
          text: "١. التميز: نسعى دائمًا لتقديم الأفضل في كل ما نقوم به. ٢. الجودة: نحن ملتزمون بتقديم خدمات ذات جودة عالية تفي بمعاييرنا. ٣. النزاهة: نعمل بشفافية ونضمن النزاهة في جميع تعاملاتنا. ٤. الاحترام: نُقدّر كل فرد ونعامل الجميع بالاحترام والكرامة."
        },
      },
      location: {
        title: "موقعنا",
        description: "ندعوك لزيارتنا واستكشاف خدماتنا",
        addressTitle: "العنوان",
        country: "الإمارات العربية المتحدة",
        governorate: "إمارة الشارقة" ,
        city: "خورفكان",
        scheduleTitle: "  مواعيد رمضان",
        BeforeIftar: "قبل الافطار",
        AfterIftar: "بعد الافطار",
        "schedule": {
      "daily": "من السبت إلى الخميس",
      "dailyTime": "من 9 صباحًا إلى 10 مساءً",
      dailyBeforeIftar: "من 9.30 صباحًا إلى 5.30 مساءً",
      dailyAfterIftar: "من 7.30 مساءً إلى 11.30 مساءً",
      


      "monday": "الإثنين",
      "mondayTime": "من 9 صباحًا إلى 1 مساءً ومن 4:30 مساءً إلى 9:30 مساءً",
      mondayBeforeIftar: "من 9.30 صباحًا إلى 1 مساءً",
      mondayAfterIftar: "من 7.30 مساءً إلى 11.30 مساءً",


      "friday": "الجمعة",
      "fridayTime": "من 4:30 مساءً إلى 10 مساءً",
      fridayAfterIftar: "من 7.30 مساءً إلى 12 منتصف الليل",

      
      "saturday": "السبت",
      "saturdayTime": "من 9 صباحًا إلى 10 مساءً",
      
      "sunday": "الأحد",
      "sundayTime": "من 9 صباحًا إلى 10 مساءً",
      
      "tuesday": "الثلاثاء",
      "tuesdayTime": "من 9 صباحًا إلى 10 مساءً",
      
      "thursday": "الخميس",
      "thursdayTime": "من 9 صباحًا إلى 10 مساءً",
      
      
      
      "wednesday": "الأربعاء",
      "wednesdayTime": "من 9 صباحًا إلى 1 مساءً ومن 4:30 مساءً إلى 9:30 مساءً",
      
        }
        ,
        viewPackages: "استعرض باقتنا"
      },
      contact: {
      "header": "تواصل معنا",
      "subHeader": "تواصل عبر الشبكات الاجتماعية",
      "address": {
        "title": "العنوان",
        "location": "الشارقة , خورفكان"
      },
      "social": {
        "facebook": "فيس بوك",
        "whatsapp": "وتس آب",
        "instagram": "انستجرام",
        "phone": "رقم الاتصال",
        "email": "البريد الالكتروني",
        "address": "العنوان"
      },
      "form": {
        "inquiry": "استفسار",
        "suggestion": "اقتراح",
        "name": "الاسم",
        "email": "البريد الالكتروني",
        "phone": "رقم الجوال",
        "message": "ملاحظات",
        "submit": "إرسال",
        "loading": "جاري الإرسال...",
        "error": "جميع الحقول مطلوبة."
      }
        },
      reportRequest: {
            title: "لطلب التقارير المخبارية",
            instructions: "يرجى ملء النموذج أدناه لتقديم طلب للحصول على تقاريرك المخبرية.",
            fullName: "الاسم الكامل",
            email: "البريد الإلكتروني",
            phone: "رقم الجوال",
            visitTime: "وقت الزيارة",
            submit: "إرسال"
          },
        withdrawal: {
        header: "السحب المنزلي",
        instructions: "يرجى ملء النموذج أدناه لتحديد موعد السحب المنزلي.",
        fullName: "الاسم الكامل",
        email: "البريد الإلكتروني",
        phone: "رقم الجوال",
        appointmentDate:"تحديد موعد",
        appointmentTime:"وقت موعد",
        notes: "ملاحظات",
        submit: "إرسال",
        chooseId: "اختر ملف الهوية",
        chooseInsurance: "اختر ملف التأمين",
      },
      booking: {
            header: "الحجز",
            noPackageData: "لا توجد بيانات الحزمة. يرجى العودة للصفحة السابقة.",
            price: "السعر",
            offerEnds: "ينتهي العرض خلال",
            name: "الاسم",
            namePlaceholder: "أدخل اسمك",
            email: "البريد الالكتروني",
            emailPlaceholder: "أدخل بريدك الالكتروني",
            phone: "رقم الجوال",
            phonePlaceholder: "أدخل رقم جوالك",
            notes: "ملاحظات",
            notesPlaceholder: "أي ملاحظات إضافية",
            successMessage: "تم إرسال الطلب بنجاح!",
            errorMessage: "خطأ",
            generalErrorMessage: "حدث خطأ أثناء إرسال الطلب. حاول مرة أخرى.",
            tests:"فحص",
            submit: "احجز الان",
          },

      footer:{
        "sections": [
          {
            "header": "مختبر مركز الشفاء",
            "links": [
              { "label": "من نحن", "url": "/aboutPage" },
              { "label": "تواصل معنا", "url": "/contact" },
              { "label": "الاستبيان", "url": "https://surveyheart.com/form/5e89c1aef411e847dc5706ca" }
            ]
          },
          {
            "header": "خدماتنا",
            "links": [
              { "label": "العروض", "url": "/offer" },
              { "label": "التحاليل المخبرية", "url": "/reports" },
              { "label": "السحب المنزلي","url": "/withdraw" }
            ]
          },
          {
            "header": "الدعم",
            "links": [
              { "label": "معلومات الطبية", "url": "/information" },
              { "label": "سياسة الخصوصية", "url": "/privacypolicy" },
              { "label": "معلومات المطور", "url": "https://muhammedaly.vercel.app/" }
            ]
          }
        ],
        "socialMedia": {
          "apple": "آبل",
          "facebook": "فيسبوك",
          "twitter": "تويتر",
          "shop": "متجر",
          "whatsapp": "واتساب"
        },
        "rights": {
          "text": " جميع الحقوق محفوظة لـ",
          "companyName1": "مختبر",
          "companyName2": " مركز الشفاء ",
          "companyName3": " الطبي",
          "year":"2024 ©"
        }
      },
      opinion: {
    "title": "أراء متابعينا",
    "subheading": "تعليقاتكم تلهمنا لتقديم الأفضل دائمًا",
    "googleReviewButton": "اكتب مراجعتك على جوجل"
  },
      currency:"درهم إماراتي",
      discount:"الخصم",
      day:"يوم",
      days:"أيام"
    },
    
  },
};
const savedLanguage = localStorage.getItem('language') || 'eng';


i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: savedLanguage, // default language
    fallbackLng: 'eng', // fallback language
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
