import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { IoArrowForward } from 'react-icons/io5'; 
import { Link } from 'react-router-dom';
import LocationImage from "../../images/Assets/locaTion2.jpg";
import { useTranslation } from 'react-i18next';
import { FaMoon, FaStarAndCrescent, FaClock, FaCalendarAlt } from "react-icons/fa";

// Reusable Component for Address Item
const AddressItem = ({ country, governorate, city, externalLink }) => (
  <div className="flex flex-col md:flex-row items-center w-[80%] justify-between space-y-2 md:space-y-0 md:space-x-4 p-4 rounded-lg shadow-lg bg-backgroundColor2 border border-secondColor transition-transform duration-300">

    {/* Country Section */}
    <div className="flex items-center gap-[5px] text-secondColor rounded-lg">
      <FaMapMarkerAlt className="text-2xl" />
      <h2 className="text-xl font-semibold">{country}</h2>
    </div>

    {/* Governorate Section */}
    <div className="flex items-center">
      <h2 className="text-xl font-semibold text-secondColor">{governorate}</h2>
    </div>

    {/* City Section with Highlighted Focus */}
    <a
      href={externalLink}
      target="_blank" // Opens link in a new tab
      rel="noopener noreferrer" // Security best practice
      className="flex items-center animate-bounce space-x-2 text-white bg-secondColor hover:bg-secondColor transition duration-300 px-4 py-2 rounded-lg hover:scale-105 w-full md:w-auto"
    >
      <span className="text-2xl font-bold">{city}</span>
      <IoArrowForward className="text-4xl" />
    </a>
  </div>
);

// Reusable Component for Worker Time Schedule
const WorkerSchedule = ({ day, time }) => (
  <>
  {day&&time&&
    (
    <div className="flex flex-col md:flex-row justify-between w-[50%] mx-auto  rounded-lg shadow-md bg-backgroundColor2 items-center text-lg">
    {/* Day Section */}
    <span className="px-6 py-2 w-full md:w-[30%] bg-secondColor text-backgroundColor rounded-lg shadow-md font-bold text-center">
      {day}
    </span>

    {/* Time Section */}
    <span className="px-6 py-2 w-full md:w-[70%] text-green-700 rounded-lg text-center">
      {time}
    </span> 
  </div>
    )
  }
  <span className="px-6 py-2 w-full md:w-auto mx-2 bg-secondColor text-backgroundColor rounded-lg shadow-md font-bold text-center">
    {day}
  </span>
  </>
);
const WorkerScheduleRamadan = ({ day, beforeIftar, afterIftar, t }) => {
  // Check if the day is Friday (case-insensitive)
  const isFriday = day && day.toLowerCase().includes("friday");

  return (
    <>
      {day && (beforeIftar || afterIftar) ? (
        <div className="relative flex flex-col md:flex-row justify-between w-[90%]  mx-auto rounded-2xl shadow-xl bg-thirdColor text-white mb-8 p-6 transition-all duration-500 hover:shadow-2xl hover:shadow-gold-500/30">
          

          {/* Day Section */}
          <div className="flex items-center justify-center px-6 py-4 w-full md:w-[30%] bg-gold-600 text-navy-950 rounded-lg shadow-md font-bold text-center relative overflow-hidden transition-transform duration-300 hover:scale-105 hover:shadow-lg hover:shadow-gold-500/50">
            <span className="absolute top-0 left-0 w-full h-full opacity-15 bg-[url('https://www.transparenttextures.com/patterns/islamic-pattern.png')]"></span>
            <span className="relative z-10 text-xl font-arabic">{day}</span>
          </div>

          {/* Time Sections */}
<div className="flex flex-col w-full md:w-[70%] pl-0 md:pl-6 pt-4 md:pt-0 bg-[#000000] rounded-lg mt-4 md:mt-0 p-5 shadow-inner transition-all duration-300 hover:bg-navy-900">
  {/* Before Iftar (Hidden for Friday) */}
  {!isFriday && beforeIftar && (
    <div className="group flex gap-[10px] px-6 py-3 text-gold-200 rounded-lg text-center md:text-left bg-thirdColor bg-opacity-40 mb-2 transition-all duration-300 hover:bg-opacity-60 hover:translate-x-2 hover:shadow-md hover:shadow-gold-500/30">
        <span className="relative text-[#F4E862]">
          {t('location.BeforeIftar')}
          <span className="absolute -bottom-1 left-0 w-full h-[2px] bg-gradient-to-r from-gold-400 to-transparent opacity-0 transition-opacity duration-500 group-hover:opacity-100"></span>
        </span>
      <span className="relative">
        {beforeIftar}
        <span className="absolute -bottom-1 left-0 w-full h-[2px] bg-gold-400 opacity-0 transition-opacity duration-500 group-hover:opacity-100"></span>
      </span>
    </div>
  )}

  {/* After Iftar */}
  {afterIftar && (
    <div className="group flex gap-[10px]  px-6 py-3  rounded-lg text-start md:text-left  bg-thirdColor bg-opacity-40 transition-all duration-300 hover:bg-opacity-60 hover:translate-x-2 hover:shadow-md hover:shadow-gold-500/30">
        
        <span className="relative text-[#F4E862]">
          {t('location.AfterIftar')}
          {/* Decorative Underline */}
          <span className="absolute -bottom-1 left-0 w-full h-[2px] bg-gradient-to-r from-gold-400 to-transparent opacity-0 transition-opacity duration-500 group-hover:opacity-100"></span>
        </span>
      <span className="relative">
        {afterIftar}
        <span className="absolute -bottom-1 left-0 w-full h-[2px] bg-gold-400 opacity-0 transition-opacity duration-500 group-hover:opacity-100"></span>
      </span>
    </div>
  )}
</div>
        </div>
      ) : (
        <div className="flex justify-center">
          <span className="px-6 py-3 w-full md:w-auto mx-2 bg-gold-600 text-navy-950 rounded-lg shadow-md font-bold text-center transition-transform duration-300 hover:scale-105 hover:shadow-lg hover:shadow-gold-500/50">
            {day || "No Schedule Available"}
          </span>
        </div>
      )}
    </>
  );
};


const Location = () => {
  const { t } = useTranslation(); // Use translation hook

  return (
    <div className="min-h-screen bg-backgroundColor p-4">
      {/* Header */}
      <header className="text-center py-4">
          <h1 className="text-4xl font-bold text-primeColor mb-2 after:block after:w-[150px] after:h-1 after:bg-secondColor after:mx-auto after:mt-2">
            {t('location.title')}
          </h1>
          <h2 className="text-xl font-medium text-secondColor">
            {t('location.description')}
          </h2>
      </header>

      {/* Location Image */}
      <div className="w-full h-[200px] mb-8">
        <img 
          src={LocationImage} 
          alt="Location" 
          className="w-full h-full object-cover rounded-lg shadow-lg" 
        />
      </div>

      {/* Location Address */}
      <section className="text-center mb-12">
        <h2 className="text-xl font-bold text-secondColor mb-4 after:block after:w-[150px] after:h-[1px] after:bg-secondColor after:mx-auto after:mt-2">
          {t('location.addressTitle')}
        </h2>
        <div className="flex items-center justify-center space-x-12">
          <AddressItem
            country={t('location.country')}
            governorate={t('location.governorate')}
            city={t('location.city')}
            externalLink={"https://www.google.com/maps/place/Alshifa+medical+laboratory/@25.3429584,56.3490257,17z/data=!3m1!4b1!4m6!3m5!1s0x3ef44f82e0f0798d:0xcbf6eba9f41c3fcb!8m2!3d25.3429584!4d56.3490257!16s%2Fg%2F11kj_8l5q_?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D"}
          />
        </div>
      </section>

      {/* Separation Line */}
      <hr className="border-t-2 border-secondColor mb-12" />

      {/* Worker Time */}
      {/* <div className="text-center mb-12 space-y-8">
        <h2 className="text-xl font-bold text-thirdColor mb-4 after:block after:w-[150px] after:h-[1px] after:bg-secondColor after:mx-auto after:mt-2">
          {t('location.scheduleTitle')}
        </h2>
        <div className="flex flex-col gap-6">
            <div className="p-4 rounded-lg bg-gray-100 shadow-md">
              <div className="flex flex-wrap justify-center">
                <WorkerSchedule day={t('location.schedule.daily')} />
                
              
              
                
              </div>
            </div>

            <div className="p-4 rounded-lg  bg-gray-100 shadow-md">
              <div className="flex flex-wrap  justify-center">
                <WorkerSchedule day={t('location.schedule.monday')} />
               
              
              </div>
            </div>

            <div className="p-4 rounded-lg bg-gray-100 shadow-md">
              <div className="flex justify-center">
                <WorkerSchedule day={t('location.schedule.friday')} />
                
              </div>
            </div>
        </div>


      </div> */}

        {/* Worker Time */}
        <div className="relative text-center mb-12 space-y-10 bg-navy-950 py-12 px-4 rounded-2xl shadow-2xl overflow-hidden">
    {/* Background Islamic Pattern */}
    <div className="absolute inset-0 opacity-10 bg-[url('https://res.cloudinary.com/dgcfs0gyw/image/upload/v1741193824/360_F_444846201_QKklRZQs4gaKNrCty9tXbKjJiCjCKnfh_znfknn.jpg')]"></div>
    {/* Starry Overlay */}
    <div className="absolute inset-0 opacity-20 bg-[url('https://res.cloudinary.com/dgcfs0gyw/image/upload/v1741193984/golden-glitter-wave-abstract-illustration-golden-star-dust-trail-sparkling-particles-isolated-magic-concept-png_zwgbsk.png')]"></div>

    {/* Section Title with Decorative Crescent */}
    <div className="relative">
    
      <h2 className="text-3xl font-bold text-thirdColor mb-6 font-arabic after:block after:w-[200px] after:h-[2px] after:bg-gold-400 after:mx-auto after:mt-4">
        {t('location.scheduleTitle')}
      </h2>
      
    </div>

    {/* Schedule Cards */}
    <div className="flex flex-col gap-8">
      {/* Daily Schedule */}
      <div className="p-6 rounded-xl  bg-opacity-80   ">
        <WorkerScheduleRamadan
          day={t('location.schedule.daily')}
          beforeIftar={t('location.schedule.dailyBeforeIftar')}
          afterIftar={t('location.schedule.dailyAfterIftar')}
          t={t}
        />
      </div>

      {/* Monday Schedule */}
      <div className="p-6 rounded-xl  bg-opacity-80  ">
        <WorkerScheduleRamadan
          day={t('location.schedule.monday')}
          beforeIftar={t('location.schedule.mondayBeforeIftar')}
          afterIftar={t('location.schedule.mondayAfterIftar')}
          t={t}
        />
      </div>

      {/* Friday Schedule */}
      <div className="p-6 rounded-xl  bg-opacity-80   ">
        <WorkerScheduleRamadan
          day={t('location.schedule.friday')}
          afterIftar={t('location.schedule.fridayAfterIftar')}
          t={t}
        />
      </div>
    </div>
  </div>
      {/* Booking Button */}
      <div className="text-center">
        <Link to="/package" className="px-6 py-3 bg-secondColor text-backgroundColor font-bold text-lg rounded-lg transition duration-300 hover:text-white hover:opacity-[70%]">
          {t('location.viewPackages')}
        </Link>
      </div>
    </div>
  );
};

export default Location;
